// 就業養成班 > 分類List
// 這頁應該不要從資料取得 ?

export const EmploymentClassList = [
  {
    title: 'AI繪圖與3D美術人才',
    subTitle: 'VR 3D Game Art Design',
    img: '/img/employmentIndex/VRgame_card.jpg',
    alt: 'AI繪圖與3D美術人才養成班',
    path: '/longterm/JAMTDCT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: 'iOS App與Mac應用軟體開發',
    subTitle: 'APP Developer Action Development',
    img: '/img/employmentIndex/APP Developer_card.jpg',
    alt: 'iOS App與Mac應用軟體開發養成班',
    path: '/longterm/JEMMPDT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: '嵌入式系統與韌體技術',
    subTitle: 'Embedded Smart System',
    img: '/img/employmentIndex/Embedded System_card.jpg',
    alt: '嵌入式系統與韌體技術就業養成班',
    path: '/longterm/JEMEMET',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: 'AIoT智慧機器人就業養成班',
    subTitle: 'AIOT IoT Wisdom Innovation Application',
    img: '/img/employmentIndex/AIoT_card.jpg',
    alt: 'AIoT智慧機器人就業養成班',
    path: '/longterm/JEAIMLT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: 'Big Data 巨量資料分析',
    subTitle: 'Data Engineering and Data Analytics',
    img: '/img/employmentIndex/Analysis Of Big Data_card.jpg',
    alt: 'Big Data巨量資料分析就業養成班',
    path: '/longterm/JJBDSET',
    place: '',
    subsidy: '補助詳情電洽',
  },
  // {
  //   title: 'AI跨界行銷資料科學專業班',
  //   subTitle: '',
  //   img: '/img/employmentIndex/Analysis Of Big Data_card.jpg',
  //   alt: 'AI跨界行銷資料科學專業班',
  //   path: '/longterm/JPDSMKT',
  //   place: '',
  //   subsidy: '補助詳情電洽',
  // },
  {
    title: '跨域Java軟體工程師',
    subTitle: 'Cross-domain Java software engineer',
    img: '/img/employmentIndex/Java_card.jpg',
    alt: '跨域Java軟體工程師就業養成班',
    path: '/longterm/JJEEITT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: '全端工程師',
    subTitle: 'Full-end engineer',
    img: '/img/employmentIndex/Full-Stack_card.jpg',
    alt: '全端工程師就業養成班',
    path: '/longterm/JMFUENT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: '智慧應用微軟C#工程師',
    subTitle: 'Smart application Microsoft C# engineer',
    img: '/img/employmentIndex/C_card.jpg',
    alt: '智慧應用微軟C#就業養成班',
    path: '/longterm/JMMSITT',
    place: '',
    subsidy: '桃園班補助',
  },
  {
    title: '雲端網路與資安工程師',
    subTitle: 'Cloud Network Smart Technology',
    img: '/img/employmentIndex/Cloud Network_card.jpg',
    alt: '雲端網路與資安工程師就業養成班',
    path: '/longterm/JNSESET',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: '前端工程師',
    // subTitle: 'Front-End Developer',
    subTitle: 'Front-end engineer',
    img: '/img/employmentIndex/Front-End Developer_card.jpg',
    alt: '前端工程師就業養成班',
    path: '/longterm/JPMFEET',
    place: '',
    subsidy: '補助詳情電洽',
  },
  // {
  //   title: 'Unity跨平台遊戲開發工程師',
  //   subTitle: 'Unity3D cross-platform game',
  //   img: '/img/employmentIndex/Unity Engineer_card.jpg',
  //   alt: 'Unity跨平台遊戲開發工程師就業養成班',
  //   path: '/longterm/JPUMVRT',
  //   place: '',
  //   subsidy: '補助詳情電洽',
  // },
  {
    title: 'Unity互動程式開發工程師',
    subTitle: 'Unity3D cross-platform game',
    img: '/img/employmentIndex/Unity Engineer_card.jpg',
    alt: 'Unity互動程式開發工程師養成班',
    path: '/longterm/JPUMVRT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: 'UI/UX創新策略網頁設計',
    subTitle: 'Interactive website UI/UX designer',
    img: '/img/employmentIndex/UIUX Designer_card.jpg',
    alt: 'UI/UX創新策略網頁設計就業養成班',
    path: '/longterm/JWUIUXT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: '網路與系統工程師',
    subTitle: 'AIOT IoT Wisdom Innovation Application',
    img: '/img/employmentIndex/AIoT_card.jpg',
    alt: '網路與系統工程師就業養成班',
    path: '/longterm/JNNWSET',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: '創新應用全端網站開發工程師',
    subTitle: '',
    img: '/img/employmentIndex/FrontBack_card.jpg',
    alt: '創新應用全端網站開發工程師就業養成班',
    path: '/longterm/JPPHPDT',
    place: '台中',
    subsidy: '補助詳情電洽',
  },
  // {
  //   title: 'AI 人工智慧創新應用',
  //   subTitle: 'Applied Artificial Intelligence',
  //   img: '/img/employmentIndex/AI_card.jpg',
  //   alt: 'AIoT智慧機器人就業養成班',
  //   path: '/longterm/JJAIENT',
  //   place: '',
  //   subsidy: '補助詳情電洽',
  // },
  // {
  //   title: 'AI 與深度學習應用工程師',
  //   subTitle: 'AIOT IoT Wisdom Innovation Application',
  //   img: '/img/employmentIndex/AIoT_card.jpg',
  //   alt: 'AI 與深度學習應用工程師就業養成班',
  //   path: '/longterm/JEAIOTT',
  //   place: '',
  //   subsidy: '補助詳情電洽',
  // },
  // {
  //   title: '全方位IT工程師',
  //   subTitle: 'IT',
  //   img: '/img/employmentIndex/IT_card.jpg',
  //   alt: '全方位IT工程師就業養成班',
  //   path: '/longterm/JWITENT',
  //   place: '高雄',
  //   subsidy: '補助詳情電洽',
  // },
  // {
  //   title: '5G+AI跨域智慧天線設計工程師',
  //   subTitle: '',
  //   img: '/img/employmentIndex/5G_card.jpg',
  //   alt: '5G+AI跨域智慧天線設計工程師就業養成班',
  //   path: '/longterm/JN5GAIT',
  //   place: '',
  //   subsidy: '補助詳情電洽',
  // },
  {
    title: '智慧製造軟體測試工程師養成班(工研院新尖兵計劃)',
    subTitle: '',
    img: '/img/employmentIndex/JMSMQAT.jpg',
    alt: '智慧製造軟體測試工程師養成班(工研院新尖兵計劃)',
    path: '/longterm/JMSMQAT',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: 'PHP後端工程師養成訓練班',
    subTitle: '',
    img: '/img/employmentIndex/JPPHPST.jpg',
    alt: 'PHP後端工程師養成訓練班',
    path: '/longterm/JPPHPST',
    place: '',
    subsidy: '補助詳情電洽',
  },
  {
    title: '全方位JavaScript工程師',
    subTitle: '',
    img: '/img/employmentIndex/JWJETPT.jpg',
    alt: '全方位JavaScript工程師',
    path: '/longterm/JWJETPT',
    place: '',
    subsidy: '補助詳情電洽',
  },
]
