import React, { useState } from 'react'
import LongTermItems from './LongTermItems'
// import SubsidyItems from './SubsidyItems'
import LongTermExploreItems from './LongTermExploreItems'
import { Link } from 'react-router-dom'

// 就業養成班Dropdown
function DropdownLongTerm(props) {
  const { setOptionTab, setOptionTypeTab } = props

  // 就業養成班選項預設是消失狀態, 點擊每個分類之後到達分頁呈現消失
  const [click, setClick] = useState(false)

  // 探索課程分類預設為不顯示, hover出現
  const [longTermExploreDisplay, setLongTermExploreDisplay] = useState(false)

  // 養成班預設為不顯示, hover出現
  const [longTermDisplay, setLongTermDisplay] = useState(false)

  // 政府補助預設為不顯示, hover出現
  const [subsidyDisplay, setSubsidyDisplay] = useState(false)

  const handleMouseLeave = () => {
    setLongTermDisplay(false)
    setSubsidyDisplay(false)
    setLongTermExploreDisplay(false)
  }

  // hover課程探索
  // const handleMouseLeaveLongTermExplore = () => {
  //   if (longTermDisplay) {
  //     setLongTermDisplay(false)
  //     setLongTermExploreDisplay(true)
  //   } else {
  //     setLongTermExploreDisplay(true)
  //   }

  //   if (subsidyDisplay) {
  //     setSubsidyDisplay(false)
  //     setLongTermExploreDisplay(true)
  //   } else {
  //     setLongTermExploreDisplay(true)
  //   }
  // }

  // hover課程一覽
  const handleMouseLeaveLongTerm = () => {
    if (longTermExploreDisplay) {
      setLongTermExploreDisplay(false)
      setLongTermDisplay(true)
    } else {
      setLongTermDisplay(true)
    }

    if (subsidyDisplay) {
      setSubsidyDisplay(false)
      setLongTermDisplay(true)
    } else {
      setLongTermDisplay(true)
    }
  }

  // hover政府補助
  // const handleMouseLeaveSubsidy = () => {
  //   if (longTermDisplay) {
  //     setLongTermDisplay(false)
  //     setSubsidyDisplay(true)
  //   } else {
  //     setSubsidyDisplay(true)
  //   }

  //   if (longTermExploreDisplay) {
  //     handleMouseLeaveLongTerm(false)
  //     setSubsidyDisplay(true)
  //   } else {
  //     setSubsidyDisplay(true)
  //   }
  // }

  // 切換狀態
  const handleClick = () => setClick(!click)

  return (
    <>
      <ul
        onClick={handleClick}
        className={
          click ? 'customDropdown-menu clicked' : 'customDropdown-menu'
        }
        onMouseLeave={handleMouseLeave}
      >
        <li className="classificationTheme">
          <div className="dropdown-content_inner">
            <h2 className="titleC__h3">
              <div className="dropdown-title">
                <span className="section-content_en">就業養成</span>
              </div>
              <span className="lesson-content_ch d-block mt-3">LongTerm</span>
              {/* 要不要做一個總覽課程頁面? */}
            </h2>
          </div>
        </li>
        <li className="classificationTheme">
          <div className="dropdown_primaryClassification_div">
            <ul>
              {/* <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeaveLongTermExplore}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/longterm"
                  onClick={() => {
                    setClick(false)
                    setOptionTab(3)
                  }}
                >
                  課程探索
                </Link>
              </li> */}
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeaveLongTerm}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/longterm"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  課程一覽
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/informationmeeting"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  說明會一覽
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                onMouseEnter={handleMouseLeave}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/job"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  就業媒合
                  {/* 廠商徵才 */}
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                // onMouseEnter={handleMouseLeaveSubsidy}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/longterm"
                  onClick={() => {
                    setClick(false)
                    setOptionTab(4)
                  }}
                >
                  政府補助
                </Link>
              </li>
              <li
                className="dropdown_primaryClassification_li"
                // onMouseEnter={handleMouseLeaveSubsidy}
              >
                <Link
                  className="dropdown_primaryClassification_a"
                  to="/careers"
                  onClick={() => {
                    setClick(false)
                  }}
                >
                  轉職進修
                </Link>
              </li>
            </ul>
          </div>

          {longTermExploreDisplay && (
            <LongTermExploreItems
              handleClick={handleClick}
              setOptionTab={setOptionTab}
              setOptionTypeTab={setOptionTypeTab}
            />
          )}
          {longTermDisplay && <LongTermItems handleClick={handleClick} />}
          {/* {subsidyDisplay && (
            <SubsidyItems
              handleClick={handleClick}
              setOptionTypeTab={setOptionTypeTab}
            />
          )} */}
        </li>
      </ul>
    </>
  )
}

export default DropdownLongTerm
