import React, { useState, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import currency from 'currency.js' // 貨幣轉換
import moment from 'moment' // 格式化日期
import UseApplyMeeting from '../EmploymentClass/UseApplymeeting'
import AttenderMessage from '../EmploymentClass/AttenderMessage'
import BookerMessage from '../EmploymentClass/BookerMessage'
import { LongTermClasses, LongTermClassTag } from '../../services/LongTerm' // 處理根據班種取得開課班級列表, api
import { OJTClasses, OJTClassesTag } from '../../services/OJT' // 處理根據班種取得開課班級列表, api

// 養成班班級開課資訊
function ClassInfo(props) {
  // 會員
  const { signinBool, LongTermClassInfo } = props

  const {
    swalWithBootstrapButtons,
    submitApplyResult,
    submitReservationResult,
    getUser,
    attenderCheck,
    setAttenderCheck,
    bookerCheck,
    setBookerCheck,
    handleSubmitApply,
    handleSubmitBooker,
    setPackageData,
    handleSetInfoSourceId,
    isLoading,
    sendAttenderSubmit,
    setSendAttenderSubmit,
    sendBookerSubmit,
    setSendBookerSubmit,
    error,
    setError,
  } = UseApplyMeeting()

  // 星期幾
  const weeks = ['日', '一', '二', '三', '四', '五', '六']

  // 沒登入轉址
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState('')

  // 課程資訊list
  const [longTermClasses, setLongTermClasses] = useState([])
  console.log(longTermClasses)
  // 班級tag
  // eslint-disable-next-line no-unused-vars
  const [longTermClassTag, setLongTermClassTag] = useState([])

  // 包裝新的組合陣列用的
  const [newLongTermClasses, setNewLongTermClasses] = useState([])
  // console.log(newLongTermClasses)

  // 取得課程list中
  // eslint-disable-next-line no-unused-vars
  const [getData, setGetData] = useState(false)

  const NTD = (value) => currency(value, { precision: 0, symbol: 'NT$' })
  const location = useLocation()

  // console.log(location.pathname) //  /JMMSITT
  // console.log(location.pathname.substr(1, 7)) // 去掉 / 只剩 JMMSITT
  const classTypeId = location.pathname.substr(10, 7)
  const classOjtTypeId = location.pathname.substr(5, 7)
  console.log(classOjtTypeId)
  // 報名或預約說明會
  const handleApply = (e, id) => {
    e.preventDefault()

    if (signinBool) {
      // 有登入，接下來就判對是否已經安排好了說明會

      // dataset.briefing => data-briefing 判定
      if (e.target.dataset.briefing === 'yes') {
        // 有說明會就將briefingID傳到Server(報名說明會)
        // console.log('不對')
        let jsonData = {}

        // 出現視窗
        setAttenderCheck(true)
        setBookerCheck(false)

        // 打包要送出的json
        jsonData = {
          customerId: getUser().customerId, // 從localStorage取出
          briefingID: id,
          Flag: 0,
          // InfoSource: infoSourceId,
        }
        // 裝起來
        setPackageData(jsonData)
        // 處理勾選消息來源
        handleSetInfoSourceId()

        // 回傳datas的結果
        // {attenderID: 4, customerId: 14, briefingID: "93e2a769-a584-4042-acd2-1f645ba11dfb", flag: 0, createdDate: "2021-08-20T15:30:56.1030293+08:00"}
      } else if (e.target.dataset.briefing === 'no') {
        let jsonData = {}

        // 沒有說明會就將classID傳到Server(專人通知)
        // let jsonData = {
        //   customerId: getUser().customerId, // 從localStorage取出
        //   classId: id,
        // }
        // 出現視窗
        setBookerCheck(true)
        setAttenderCheck(false)

        // 打包要送出的json
        jsonData = {
          customerId: getUser().customerId, // 從localStorage取出
          classId: id,
        }
        // 裝起來
        setPackageData(jsonData)

        // 處理勾選消息來源
        handleSetInfoSourceId()

        // 回傳datas的結果
        // {bookerId: 5, customerId: 14, classId: "JMMSITT2106", createdDate: "2021-08-20T15:31:01.8151251+08:00"}
      }
    } else {
      swalWithBootstrapButtons
        .fire({
          title: '尚未登入',
          html: '<figure><img src="/img/questionAlert.png" alt="" /></figure>請先登入會員，才能報名說明會。',
          padding: '60px',
          showConfirmButton: true,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: '登入',
          cancelButtonText: '取消',
          denyButtonText: '',
          backdrop: `rgba(0,0,0,.6)`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // console.log('滾去登入吧')
            setRedirectTo('signin')

            // 暫存課程代號
            localStorage.setItem(
              'EmploymentSignup',
              JSON.stringify({ class: classTypeId })
            )
          }
        })
    }
  }

  // 產生亂數, 網址使用
  const handleSuccessSide = (length) => {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  const successSide = handleSuccessSide(10)

  // 取得課程資料
  const handleGetClassTag = async () => {
    // 狀態更新(true:傳送中, false:傳送完成)
    setGetData(true)
    const datas = await LongTermClassTag(classTypeId)
    setLongTermClassTag(datas)
    setGetData(false)
  }

  // 處理兩個json組合
  const handleText = async () => {
    if (
      classTypeId.substr(0, 1) !== 'J' ||
      classOjtTypeId.substr(0, 1) === 'P'
    ) {
      // 取的課程開課資訊
      const datas = await OJTClasses()
      setLongTermClasses(datas)

      // 取得開課tag
      const dataTag = await OJTClassesTag()
      setLongTermClassTag(dataTag)

      // 取出tag陣列的classTag
      const mixClass = [...dataTag.map((v, i) => v.classTag)]

      // console.log('Tag陣列 : ', mixClass)

      // 將兩個陣列組合
      datas.forEach(function (v, i, a) {
        a[i] = { v, classTagName: mixClass[i] }
      })

      // 篩選哪個班級, 只顯示該地區該課程班級
      const classId = datas.filter(
        (item) => item.v.aClass.classID === location.pathname.substr(5, 11)
      )
      // console.log(regionClass)

      // 將新的陣列包裝成newLongTermClasses
      setNewLongTermClasses(classId)
      LongTermClassInfo(classId)
    } else {
      // console.log('J')
      // 取的課程開課資訊
      const datas = await LongTermClasses(classTypeId)
      setLongTermClasses(datas)

      // 取得開課tag
      const dataTag = await LongTermClassTag(classTypeId)
      setLongTermClassTag(dataTag)

      // 取出tag陣列的classTag
      const mixClass = [...dataTag.map((v, i) => v.classTag)]

      // console.log('Tag陣列 : ', mixClass)

      // 將兩個陣列組合
      datas.forEach(function (v, i, a) {
        a[i] = { v, classTagName: mixClass[i] }
      })

      // 篩選哪個班級, 只顯示該地區該課程班級
      const classId = datas.filter(
        (item) => item.v.aClass.classID === location.pathname.substr(10, 11)
      )

      // 將新的陣列包裝成newLongTermClasses
      setNewLongTermClasses(classId)
      LongTermClassInfo(classId)
    }
  }
  // console.log('組合 : ', newLongTermClasses)

  useEffect(() => {
    handleText()
    // handleGetClassInfo()
    handleGetClassTag()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classTypeId])

  // 報名
  useEffect(() => {
    const checkBoxId = JSON.parse(localStorage.getItem('checkedId'))

    if (checkBoxId.InfoSource.length !== 0) {
      handleSubmitApply()
    } else {
      setError('請至少勾選一個選項')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAttenderSubmit])

  // 預約
  useEffect(() => {
    const checkBoxId = JSON.parse(localStorage.getItem('checkedId'))

    if (checkBoxId.InfoSource.length !== 0) {
      handleSubmitBooker()
    } else {
      setError('請至少勾選一個選項')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendBookerSubmit])

  // 送出中
  // const spinner = (
  //   <>
  //     <div className="spinner-wrapper">
  //       <div className=""></div>
  //       <span className="loading" data-name="Loading">
  //         Loading
  //       </span>
  //     </div>
  //   </>
  // )

  return (
    <>
      {/* 不同的情況下改變轉址 */}
      {redirectTo === 'signin' && <Redirect to="/signin" />}
      {/* 有說明會且報名成功 */}
      {submitApplyResult === true && (
        <Redirect to={`/longterm/signupsuccess/${successSide}`} />
      )}
      {/* 沒有說明會但預約成功 */}
      {submitReservationResult === true && (
        <Redirect to={`/longterm/reservationsuccess/${successSide}`} />
      )}

      <div className={isLoading ? 'spinner-wrapper_content' : 'd-none'}>
        <div className="spinner-wrapper mt-0">
          <span className="loading" data-name="Loading">
            Loading
          </span>
        </div>
      </div>

      <div className="bcBlockInner__div pt-0">
        <h3 className="black">開課資訊</h3>
        <div>
          <ul>
            {longTermClasses.length === 0 ? (
              <div className="classListC__div text-center blue">尚無課程</div>
            ) : (
              <>
                {newLongTermClasses.map((item, i) => {
                  return (
                    <li key={i} className="longTerm_class_li">
                      <div className="longTerm_class_div">
                        <div className="longTerm_classInner_div">
                          <div className="longTerm_innerBriefing_div">
                            <p>
                              <span>
                                {item.v.classBriefing &&
                                item.v.classBriefing.flag === true ? (
                                  moment(
                                    item.v.classBriefing.briefingDate
                                  ).format('YYYY') + '年'
                                ) : item.v.aClass.classID === 'JMSMQAT2401' ? (
                                  <>
                                    您可以點擊按鈕連結
                                    <br />
                                    前往報名說明會
                                  </>
                                ) : (
                                  <>
                                    說明會時間未確定
                                    <br />
                                    您可以預約搶先得知最新資訊
                                  </>
                                )}
                              </span>
                              <strong>
                                {item.v.classBriefing &&
                                item.v.classBriefing.flag === true
                                  ? moment(
                                      item.v.classBriefing.briefingDate
                                    ).format('MM/DD') +
                                    '(' +
                                    weeks[
                                      moment(
                                        item.v.classBriefing.briefingDate
                                      ).day()
                                    ] +
                                    ')'
                                  : ''}
                              </strong>
                              <span>
                                {item.v.classBriefing === null ||
                                item.v.classBriefing.flag === false ? (
                                  ''
                                ) : (
                                  <>{item.v.classBriefing.briefingTime}</>
                                )}
                              </span>
                              {item.v.classBriefing === null ||
                              item.v.classBriefing.flag === false ? (
                                ''
                              ) : item.v.classBriefing.briefingArea.includes(
                                  '線上'
                                ) ? (
                                <span className="card-content_area mt-0 ">
                                  線上說明會
                                </span>
                              ) : (
                                <span className="card-content_area mt-0">
                                  實體說明會
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="longTerm_innerClass_div">
                            <div className="longTerm_ClassContent_div">
                              <div>
                                <h6>
                                  {item.v.aClass.address.substring(0, 2)}
                                  開課
                                </h6>
                                <h6 className="d-flex">
                                  <div>上課期間</div>
                                  <div className="pl-3">
                                    {(() => {
                                      switch (item.v.aClass.classID) {
                                        case 'JNSESET2403':
                                          return (
                                            <span className="onJob-txt_date">
                                              113年10月中旬開課
                                            </span>
                                          )
                                        case 'JPUMVRT2402':
                                          return (
                                            <span className="onJob-txt_date">
                                              113年11月上旬開課
                                            </span>
                                          )
                                        case 'JEMEMET2402':
                                          return (
                                            <span className="onJob-txt_date">
                                              113年12月上旬開課
                                            </span>
                                          )
                                        default:
                                          return (
                                            <span className="onJob-txt_date">
                                              {moment(
                                                item.v.aClass.startDate
                                              ).format('YYYY/MM/DD') +
                                                '-' +
                                                moment(
                                                  item.v.aClass.endDate
                                                ).format('YYYY/MM/DD')}
                                            </span>
                                          )
                                      }
                                    })()}
                                    {/* <span className="onJob-txt_date">
                                      {moment(item.v.aClass.startDate).format(
                                        'YYYY/MM/DD'
                                      ) +
                                        '-' +
                                        moment(item.v.aClass.endDate).format(
                                          'YYYY/MM/DD'
                                        )}
                                    </span> */}
                                  </div>
                                </h6>
                                <span className="onJob-txt_hr ml-0 px-2">
                                  總時數 {item.v.aClass.length} hr
                                </span>
                              </div>
                              <div className="classListInner__div--right">
                                <div>
                                  <h6 className="priceTxt--orange">
                                    {item.v.aClass.companyName !== '' &&
                                    item.v.aClass.taxCode !== ''
                                      ? item.v.aClass.classID ===
                                          'JMMSITT2405' ||
                                        item.v.aClass.classID === 'JNSESET2402'
                                        ? '學員自行負擔費用'
                                        : '計劃核定價'
                                      : '優惠價 '}
                                    {NTD(
                                      item.v.aClass.tuitionDiscount
                                    ).format()}
                                  </h6>
                                  <h6 className="priceTxt--original">
                                    {NTD(item.v.aClass.tuition).format()}
                                  </h6>
                                </div>

                                <div className="pt-4">
                                  {(() => {
                                    switch (item.v.aClass.flag) {
                                      case '1':
                                        return (
                                          <span>
                                            {item.v.classBriefing &&
                                            item.v.classBriefing.maxAttender ===
                                              1000 ? (
                                              <button
                                                type="text"
                                                className="employmentClassification-content_button_disable"
                                              >
                                                說明會報名已額滿
                                              </button>
                                            ) : item.v.aClass.classID ===
                                              'JMSMQAT2401' ? (
                                              <a
                                                href="https://docs.google.com/forms/d/e/1FAIpQLSf4UCbSwNpJlI_6lB5IQnGWG34I6igeUA6qGOXtoq3wEkUHiA/viewform"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <div className="customViewMoreButton w-100">
                                                  前往報名說明會
                                                </div>
                                              </a>
                                            ) : (
                                              <button
                                                type="submit"
                                                className={
                                                  item.v.classBriefing === null
                                                    ? 'employmentClassification-content_button'
                                                    : 'employmentClassification-content_button'
                                                }
                                                data-briefing={
                                                  item.v.classBriefing !==
                                                    null &&
                                                  item.v.classBriefing.flag ===
                                                    true
                                                    ? 'yes'
                                                    : 'no'
                                                }
                                                onClick={(e) =>
                                                  handleApply(
                                                    e,
                                                    item.v.classBriefing !==
                                                      null &&
                                                      item.v.classBriefing
                                                        .flag === true
                                                      ? item.v.classBriefing
                                                          .briefingID
                                                      : item.v.aClass.classID
                                                  )
                                                }
                                              >
                                                {item.v.classBriefing !==
                                                  null &&
                                                item.v.classBriefing.flag ===
                                                  true
                                                  ? '報名說明會'
                                                  : '預約說明會'}
                                              </button>
                                            )}
                                          </span>
                                        )
                                      case '12':
                                        return (
                                          <button
                                            type="text"
                                            className="employmentClassification-content_button_disable"
                                          >
                                            班級報名已額滿
                                          </button>
                                        )
                                      case '13':
                                        return (
                                          <button
                                            type="text"
                                            className="employmentClassification-content_button_candidate"
                                          >
                                            額滿請電洽
                                          </button>
                                        )

                                      default:
                                        return null
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                            <div className="tagC__div">
                              {/* 第一個tag */}
                              {item.classTagName === null ? (
                                <h6 className="d-none">
                                  <span></span>
                                </h6>
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <h6
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[0]
                                          ? 'tagInner__h6'
                                          : ''
                                      }
                                    >
                                      <span>
                                        {
                                          item.classTagName.classTagName.split(
                                            ','
                                          )[0]
                                        }
                                      </span>
                                    </h6>
                                  )}
                                </>
                              )}
                              {/* 第二個tag */}
                              {item.classTagName === null ? (
                                <h6 className="d-none">
                                  <span></span>
                                </h6>
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <h6
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[1]
                                          ? 'tagInner__h6'
                                          : ''
                                      }
                                    >
                                      <span>
                                        {
                                          item.classTagName.classTagName.split(
                                            ','
                                          )[1]
                                        }
                                      </span>
                                    </h6>
                                  )}
                                </>
                              )}
                              {/* 第三個tag */}
                              {item.classTagName === null ? (
                                <h6 className="d-none">
                                  <span></span>
                                </h6>
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <h6
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[2]
                                          ? 'tagInner__h6'
                                          : ''
                                      }
                                    >
                                      <span>
                                        {
                                          item.classTagName.classTagName.split(
                                            ','
                                          )[2]
                                        }
                                      </span>
                                    </h6>
                                  )}
                                </>
                              )}
                              {/* 到第四個tag */}
                              {item.classTagName === null ? (
                                <h6 className="d-none">
                                  <span></span>
                                </h6>
                              ) : (
                                <>
                                  {item.classTagName.classTagName === null ? (
                                    ''
                                  ) : (
                                    <h6
                                      className={
                                        item.classTagName.classTagName.split(
                                          ','
                                        )[3]
                                          ? 'tagInner__h6'
                                          : ''
                                      }
                                    >
                                      <span>
                                        {
                                          item.classTagName.classTagName.split(
                                            ','
                                          )[3]
                                        }
                                      </span>
                                    </h6>
                                  )}
                                </>
                              )}
                            </div>
                            <div>
                              <h6 className="onJob-txt_mark purple">
                                {item.classTagName === null ? (
                                  ''
                                ) : (
                                  <>{item.classTagName.classMemo}</>
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </>
            )}
          </ul>
        </div>
      </div>

      {/* 確認送出報名視窗 */}
      <div className={attenderCheck ? 'd-flex' : 'd-none'} id="discountMessage">
        <AttenderMessage
          attenderCheck={attenderCheck}
          setAttenderCheck={setAttenderCheck}
          sendAttenderSubmit={sendAttenderSubmit}
          setSendAttenderSubmit={setSendAttenderSubmit}
          error={error}
        />
      </div>
      {/* 確認送出報名視窗 */}

      {/* 確認送出預約視窗 */}
      <div className={bookerCheck ? 'd-flex' : 'd-none'} id="discountMessage">
        <BookerMessage
          bookerCheck={bookerCheck}
          setBookerCheck={setBookerCheck}
          sendBookerSubmit={sendBookerSubmit}
          setSendBookerSubmit={setSendBookerSubmit}
          error={error}
        />
      </div>
      {/* 確認送出預約視窗 */}
    </>
  )
}

export default ClassInfo
